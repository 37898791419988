import Menu from './components/Menu';
import Page from './pages/Page';
import DebateList from './pages/DebateList';
import ViewDebate from './pages/ViewDebate';
import ViewArgument from './pages/ViewArgument';
import EditArgument from './pages/EditArgument';
import NewDebate from './pages/NewDebate';
import NewArgument from './pages/NewArgument';
import SlideArguments from './pages/SlideArguments';
import Profile from './pages/Profile';
import Login from './pages/Login';
import SignUp from './pages/SignUp';

import React from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthProvider } from './api/context';
import PrivateRoute from './components/PrivateRoute'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/media.css'

const App: React.FC = () => {

  return (
    <AuthProvider>
      <IonApp id="main-app">
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Switch>
                <PrivateRoute path="/page/:name" component={Page} exact />
                <PrivateRoute path="/debateList" component={DebateList} exact />
                <PrivateRoute path="/debates/:id" component={ViewDebate} exact />
                <PrivateRoute path="/debates/:id/arguments/:argId" component={ViewArgument} exact/>
                <PrivateRoute path="/debates/:id/arguments/:argId/edit" component={EditArgument} />
                <PrivateRoute path="/new-debate" component={NewDebate} />
                <PrivateRoute path="/new-argument/:id" component={NewArgument} />
                <PrivateRoute path="/profile/:uid" component={Profile} />
                <PrivateRoute path="/arguments/:id" component={SlideArguments} />
                <Route path="/login" component={Login} />
                <Route path="/sign-up" component={SignUp} />
                <Redirect from="/" to="/debateList" exact />
              </Switch>
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    </AuthProvider>
  );
};

export default App;
