import React, {useState} from "react";
import { Link } from "react-router-dom";
import { IonPage, IonContent, IonInput, IonList, IonItem, IonButton, IonLabel, IonHeader, IonToolbar, IonTitle, IonToast } from '@ionic/react';
import { useAuth } from '../api/context';

const Login = ({...props}) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { currentUser, login } = useAuth();

  const handleLogin = () => {
    setError('')
    login(email, password)
    .then(res => props.history.push("/"))
    .catch(e => setError(e.message))
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        {console.log(currentUser)}
        <IonToast isOpen={!!error} message={error} color='warning'/>
        <IonList lines="full">
          <IonItem>
            <IonLabel position="stacked">Email</IonLabel>
            <IonInput
              type="email"
              onIonChange={(e) => {setEmail(e.detail.value);}}
              name="email"
              value={email}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Password</IonLabel>
            <IonInput
              type="password"
              onIonChange={(e) => {setPassword(e.detail.value);}}
              name="email"
              value={password}
            />
          </IonItem>
        </IonList>
        <IonButton onClick={handleLogin} expand="block" class="ion-margin">Login</IonButton>
        <div className="ion-text-center">
          <p>Don't have an account?</p>
          <Link to="/sign-up">Sign up</Link>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default Login;
