import React, { useState } from "react";
import { useAsyncEffect } from 'use-async-effect';
import { getUser } from '../api/data'
import { IonContent, IonAvatar } from '@ionic/react'
import Layout from './Layout';
import '../theme/profile.css';

const Profile = ({match, ...props}) => {

  const uid = match.params.uid
  const [user, setUser] = useState(null)

  useAsyncEffect(async() => {
    const user = await getUser(uid)
    setUser(user);
  }, [uid]);

  return (
    <Layout name='User profile' backButton={true}>
      <IonContent id='profile'>
        <div style={{backgroundColor: 'black', height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <IonAvatar>
            <img alt={user?.displayName} src={user?.imageUrl} />
          </IonAvatar>
          {user?.displayName}
          {user?.bio}
        </div>
      </IonContent>
    </Layout>
  )
};
export default Profile;
