import { IonButtons, IonContent, IonHeader, IonMenuButton, IonBackButton, IonPage, IonTitle, IonToolbar, IonIcon, IonButton } from '@ionic/react';
import { createOutline } from 'ionicons/icons';
import React from 'react';

const Layout = ({name, backButton = false, edit = null , children}) => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {backButton ? (
              <IonBackButton />
            ) :
              <IonMenuButton />
            }
          </IonButtons>
          {edit?.isOwner &&
            <IonButtons slot="end">
              <IonButton routerLink={edit.path}>
                <IonIcon icon={createOutline}/>
              </IonButton>
            </IonButtons>
          }
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {children}
      </IonContent>
    </IonPage>
  );
};

export default Layout;
