import { IonContent, IonItem, IonProgressBar, IonButton, IonList, IonNote, IonItemGroup, IonLabel } from '@ionic/react';
import React, { useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import Layout from './Layout';
import { db } from "../firebase";

const ViewDebate = ({match}) => {

  const debateId = match.params.id;
  const [debate, setDebate] = useState(null);
  const [args, setArgs] = useState([]);
  const [loading, setLoading] = useState(true);

  useAsyncEffect(async() => {
    const debate = await db.collection('debates').doc(debateId).get()
    const args = await db.collection('debates').doc(debateId).collection('arguments').orderBy('score','desc').get()
    .then(snapshot => snapshot.docs.map(doc => ({id:doc.id, ...doc.data()})))
    setDebate(debate.data())
    setArgs(args)
    setLoading(false)
  }, [debateId]);

  return (
    <Layout name='Debate'>
    {loading ? (
      <IonProgressBar type="indeterminate"/>
    ) : (
      <IonContent>
        <IonItemGroup class="ion-padding">
          <h2>{debate?.title}</h2>
          <p>{debate?.description}</p>
          <IonButton expand="block" routerLink={'/arguments/'+debateId}>Read arguments</IonButton>
          <IonButton expand="block" routerLink={'/new-argument/'+debateId}>Contribute</IonButton>
        </IonItemGroup>
        <IonList>
          {args.map(doc => (
            <IonItem key={doc.id} routerLink={'/debates/'+debateId+'/arguments/'+doc.id}>
              {doc.side ? (
                <IonNote slot="end" style={{color:'green'}}>{doc.score}</IonNote>
              ) : (
                <IonNote slot="end" style={{color:'red'}}>{doc.score}</IonNote>
              )}
              <IonLabel className="wrap">{doc.title}</IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    )}
    </Layout>
  );
};

export default ViewDebate;
