import React from 'react'
import { IonContent, IonInput, IonList, IonItem, IonTextarea, IonButton, IonSelect, IonSelectOption } from '@ionic/react';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { db } from '../firebase'
import Layout from './Layout';
import topics from '../api/topics'
import { useAuth } from '../api/context'

const NewDebate = (props) => {

  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [topic, setTopic] = useState<string>('')
  const { currentUser } = useAuth()

  async function submit() {
    const res = await db.collection('debates').add({
      title: title, description: description, topic: topic, ownerId: currentUser.uid,
    })
    props.history.push('/debates/'+res.id)
  }

  return (
    <Layout name='Add new debate' backButton={true}>
      <IonContent className='ion-padding'>
        <IonList>
          <IonItem>
            <IonInput
              maxlength={200}
              value={title}
              placeholder="Give your debate a good title"
              onIonChange={e => setTitle(e.detail.value!)}>
            </IonInput>
          </IonItem>
          <IonItem>
            <IonTextarea
              maxlength={500}
              rows={8}
              value={description}
              placeholder="Debate description"
              onIonChange={e => setDescription(e.detail.value!)}>
            </IonTextarea>
          </IonItem>
          <IonItem>
            <IonSelect
              interface='action-sheet'
              value={topic}
              placeholder="Select a topic"
              onIonChange={e => setTopic(e.detail.value)}>
              {topics.map(topic => (
                <IonSelectOption key={topic} value={topic}>{topic}</IonSelectOption>
              ))};
            </IonSelect>
          </IonItem>
        </IonList>
        <IonButton onClick={submit}>Submit new debate</IonButton>
      </IonContent>
    </Layout>
  );
};

export default withRouter(NewDebate);
