import React, { useState, useEffect, useContext } from "react";
import { auth } from "../firebase";

export const AuthContext = React.createContext(null);

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const signup = (name, email, password) => {
    return auth.createUserWithEmailAndPassword(email, password)
    .then( async res => {
      const user = auth.currentUser
      await user.updateProfile({displayName: name})
      const token = await Object.entries(res.user)[5][1].b
      await localStorage.setItem('token', token)
    })
    .catch(err => {throw err})
  }

  const login = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password)
    .catch(err => {throw err})
  }

  const signout = () => {
    return auth.signOut()
  }

  const resetPassword = (email) => {
    return auth.sendPasswordResetEmail(email)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })
    return unsubscribe
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, signup, login, signout, resetPassword }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
