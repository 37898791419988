import { IonItem, IonAvatar, IonLabel } from '@ionic/react';
import React from 'react';

const AvatarItem = ({ user }) => {

  return (
    <IonItem lines="full" routerLink={"/profile/"+user.uid}>
      <IonAvatar slot="start">
        <img alt="avatar" src={user?.imageUrl} />
      </IonAvatar>
      <IonLabel>
        <h2>{user?.name}</h2>
        <p>{user?.bio}</p>
      </IonLabel>
    </IonItem>
  )
};

export default AvatarItem
