import { IonContent, IonItem, IonProgressBar, IonButton, IonSlides, IonSlide, IonItemGroup, IonLabel } from '@ionic/react';
import React, { useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import Layout from './Layout';
import ArgDebateItem from '../components/ArgDebateItem'
import { db } from "../firebase";

const SlideArguments = ({match}) => {

  const debateId = match.params.id;
  const [debate, setDebate] = useState(null);
  const [args, setArgs] = useState([]);
  const [loading, setLoading] = useState(true);

  useAsyncEffect(async() => {
    const args = await db.collection('debates').doc(debateId).collection('arguments').orderBy('score','desc').get()
    .then(snapshot => snapshot.docs.map(doc => ({id:doc.id, ...doc.data()})))
    setArgs(args)
    const debate = await db.collection('debates/').doc(debateId).get()
    setDebate(debate.data())
    setLoading(false)
  }, [debateId]);

  return (
    <Layout name='Arguments'>
    {console.log(debate)}
    {loading ? (
      <IonProgressBar type="indeterminate"/>
    ) : (
      <IonContent>
        <IonSlides pager={true} scrollbar={true} style={{height: '100%'}}>
          {args.map(arg => (
            <IonSlide key={arg.id}>
              <div className="ion-padding-horizontal">
                <ArgDebateItem side={arg.side} debate={debate} debateId={debateId}/>
                <h2>{arg.title}</h2>
                <p style={{whiteSpace: 'pre-wrap'}}>{arg.description}</p>
              </div>
            </IonSlide>
          ))}
        </IonSlides>
      </IonContent>
    )}
    </Layout>
  );
};

export default SlideArguments;
