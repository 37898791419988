import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage"
// Initialize Firebase
export const firebaseConfig = {
    apiKey: "AIzaSyBwx1eLXybyJQB0A0hOOx3X9lJrxDLiENc",
    authDomain: "the-arguments.firebaseapp.com",
    projectId: "the-arguments",
    storageBucket: "the-arguments.appspot.com",
    messagingSenderId: "91966038135",
    appId: "1:91966038135:web:abed7113449275681d2672"
  };
//firebase.initializeApp(config);
firebase.initializeApp(firebaseConfig);
// Firebase authentication
export const auth = firebase.auth();
// Firestore db
export const db = firebase.firestore();
export const storage = firebase.storage();
// Register with Google
const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};
