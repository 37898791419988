import { IonContent, IonItem, IonAvatar, IonChip, IonActionSheet, IonListHeader, IonProgressBar, IonButton, IonBadge, IonToolbar, IonButtons, IonList, IonLabel, IonInput, IonIcon, IonSegment, IonSegmentButton } from '@ionic/react';
import { arrowUpCircleOutline, arrowDownCircleOutline, chatboxEllipsesOutline, flagOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import Layout from './Layout';
import AvatarItem from '../components/AvatarItem'
import ArgDebateItem from '../components/ArgDebateItem'
import Moment from 'react-moment';
import flags from '../api/flags'
import { db } from '../firebase';
import { upvoteArgument, downvoteArgument, addComment, addFlag, getUser } from '../api/data'
import { useAuth } from '../api/context'

const ViewArgument = ({match}) => {

  const { currentUser } = useAuth()
  const debateId = match.params.id;
  const argumentPath = match.url;

  const [debate, setDebate] = useState(null);
  const [arg, setArg] = useState(null);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newComment, setNewComment] = useState(null);
  const [userVote, setUserVote] = useState(null);
  const [owner, setOwner] = useState(null);
  const [showPicker, setShowPicker] = useState(false);

  useAsyncEffect(async() => {
    const debate = await db.collection('debates/').doc(debateId).get()
    const arg = await db.doc(argumentPath).get()
    const owner = await getUser(arg.data().ownerId)
    setDebate(debate.data())
    setOwner(owner)
    setArg(arg.data())

    const $arg = db.doc(argumentPath).get().then(snapshot => {
      setArg(snapshot.data());
    })
    const $comments = db.doc(argumentPath).collection('comments').get().then(snapshot => {
      const docs = snapshot.docs.map(doc => ({id:doc.id, ...doc.data()}));
      setComments(docs);
    })
    const $userVotes = db.doc(argumentPath).collection('votes').doc(currentUser.uid).get().then(snapshot => {
      setUserVote(snapshot.data());
    })
    setLoading(false);
  }, [debateId, argumentPath, currentUser]);

  async function submitComment() {
    addComment(argumentPath, newComment, currentUser.uid)
  }

  async function submitFlag(flagId) {
    addFlag(argumentPath, flagId, currentUser.uid)
  }

  function isOwner() {
    const isOwner = (arg?.ownerId === currentUser?.uid)
    const path = argumentPath+"/edit"
    return {isOwner, path}
  }

  return (
    <Layout name='Argument' backButton={true} edit={isOwner()}>
    {loading ? (
      <IonProgressBar type="indeterminate"/>
    ) : (
      <IonContent>
        <ArgDebateItem side={arg.side} debate={debate} debateId={debateId}/>
        <div className="ion-padding-horizontal">
          <h1>{arg.title}</h1>
          <p style={{whiteSpace: 'pre-wrap'}}>{arg.description}</p>
          <IonSegment value="neutral">
            <IonSegmentButton value="disagree" onClick={() => downvoteArgument(match.url, currentUser.uid, userVote?.vote)}>
              <IonLabel>Disagree</IonLabel>
              <IonLabel>{arg.downvoteCount}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="neutral">
              <IonIcon icon={arrowDownCircleOutline} />
            </IonSegmentButton>
            <IonSegmentButton value="agree" onClick={() => upvoteArgument(match.url, currentUser.uid, userVote?.vote)}>
              <IonLabel>Agree</IonLabel>
              <IonLabel>{arg.upvoteCount}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </div>
        <IonList>
          <AvatarItem user={owner}/>
          <IonListHeader>
            <IonLabel>Feedback</IonLabel>
            {console.log(arg)}
            <IonButton item-right onClick={() => setShowPicker(true)}>Give yours</IonButton>
          </IonListHeader>
          {arg.flags && Object.entries(arg.flags)
          .filter(([key, value]) => value !== 0)
          .map(([key, value], i) => (
            <IonItem lines="full" key={i} routerLink={"/profile/"}>
              <IonLabel>{flags.find(x => x.id === key).label}</IonLabel>
              <IonBadge slot="end">{value}</IonBadge>
            </IonItem>
          ))}
          {/* A JSX comment
          {comments.map(doc => (
            <IonItem lines="full" key={doc.id}>
              <IonAvatar slot="start">
                <img alt="avatar" src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
              </IonAvatar>
              <IonLabel>
                <p>
                  <Moment fromNow>{doc.createdAt}</Moment>
                </p>
                <span className="wrap">{doc.content}</span>
              </IonLabel>
              <IonIcon slot="end" icon={arrowUpCircleOutline} />
            </IonItem>
          ))}
          comment */}
        </IonList>
        <IonActionSheet
          isOpen={showPicker}
          onDidDismiss={()=>setShowPicker(false)}
          header='Pick a flag'
          buttons= {flags.map(flag => ({text:flag.label, handler: () => submitFlag(flag.id)}))}
        ></IonActionSheet>
      </IonContent>
    )}
    </Layout>
  );
};

export default ViewArgument;
