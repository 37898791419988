import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonButton, IonMenu, IonMenuToggle, IonNote,} from '@ionic/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { homeOutline, homeSharp, createOutline, createSharp, bookmarkOutline, mailOutline, mailSharp} from 'ionicons/icons';
import './Menu.css';
import { useAuth } from "../api/context";

const Menu = ( {...props} ) => {

  const { currentUser, signout } = useAuth();
  const location = useLocation();

  const handleSignout = () => {
    try {
      signout()
      props.history.push("/login")
    } catch {
      console.log('could not sign out')
    }
  }

  const appPages = [
    {
      title: 'Debates',
      url: '/debateList',
      iosIcon: homeOutline,
      mdIcon: homeSharp
    },
    {
      title: 'Create debate',
      url: '/new-debate',
      iosIcon: createOutline,
      mdIcon: createSharp
    },
    {
      title: 'Profile',
      url: '/profile/'+currentUser?.uid,
      iosIcon: mailOutline,
      mdIcon: mailSharp
    },
  ];

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>{currentUser?.displayName || "Anonymous user"}</IonListHeader>
          <IonNote>{currentUser?.email}</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        <IonMenuToggle autoHide={false}>
          <IonButton onClick={handleSignout}>Sign Out</IonButton>
        </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
