import { db, storage } from '../firebase'
import firebase from "firebase/app";

// Firestore operators
//const arrayUnion = firebase.firestore.FieldValue.arrayUnion
const increment = firebase.firestore.FieldValue.increment(1);
const decrement = firebase.firestore.FieldValue.increment(-1);

// Helpers
//const docData = (ref) =>


// Arguments
export const upvoteArgument = async (argumentPath, userId, currentVote) => {
  await db.doc(argumentPath).update({
    upvoteCount: increment,
    score: increment,
    ...((currentVote === false) && {downvoteCount: decrement}),
  });
  await db.doc(argumentPath).collection("votes").doc(userId).set({
    vote: true,
  }).catch(console.log);
};

export const downvoteArgument = async (argumentPath, userId, currentVote) => {
  await db.doc(argumentPath).update({
    downvoteCount: increment,
    score: decrement,
    ...((currentVote === true) && {upvoteCount: decrement}),
  });
  await db.doc(argumentPath).collection("votes").doc(userId).set({
    vote: false,
  }).catch(console.log);
};

export const addFlag = async (path, flag, userId) => {
  const arg = await db.doc(path).get()
  const user = arg.data().users?.[userId]
  const data = {
    ['flags.'+flag]: increment,
    ['users.'+userId+'.flag']: flag,
    ...(user?.flag) && {['flags.'+user.flag]: decrement}
  }
  await db.doc(path).update(data)
}

// CRUD
export const addArgument = async (data) => {
  await db.collection('debates').doc(data.debateId).collection('arguments').add({
    title: data.title,
    description: data.description,
    side: data.side,
    ownerId: data.ownerId,
    createdAt: new Date().toISOString(),
    score: 0,
  })
  await db.collection('debates').doc(data.debateId).update({
    argumentCount: increment,
  })
  await db.collection('users').doc(data.ownerId).update({
    argumentCount: increment,
  })
}

// Comments
export const addComment = async (path, content, ownerId) => {
  await db.doc(path).collection('comments').add({
    content:  content,
    ownerId: ownerId,
    createdAt: new Date().toISOString(),
  })
  await db.doc(path).update({
    commentCount: increment,
  })
  await db.collection('users').doc(ownerId).set({
    commentCount: increment,
  })
}

// Get user
export const getUser = async (uid) => {
  const userRef = db.collection('users').doc(uid)
  const user = await userRef.get()
  const imageRef = await storage.refFromURL(user.data().imageRef)
  const imageUrl = await imageRef.getDownloadURL()
  return ({imageUrl, uid:user.id, ...(user.data())})
}
