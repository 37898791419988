import React, { useState } from 'react'
import { IonContent, IonInput, IonList, IonItem, IonTextarea, IonButton, IonSegment, IonSegmentButton, IonLabel } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { addArgument } from '../api/data'
import Layout from './Layout';
import { useAuth } from '../api/context'

const NewArgument = ({...props}) => {

  const { currentUser } = useAuth()
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [side, setSide] = useState<boolean>(true)

  async function submit() {
    addArgument({
      title: title,
      description: description,
      side: side,
      ownerId: currentUser.uid,
      debateId: props.match.params.id,
    });
    props.history.push('/debates/'+props.match.params.id)
  }

  return (
    <Layout name='Add new argument' backButton={true}>
      <IonContent className='ion-padding'>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Title</IonLabel>
            <IonInput
              maxlength={200}
              value={title}
              placeholder="Give a title to the argument"
              onIonChange={e => setTitle(e.detail.value!)}>
            </IonInput>
          </IonItem>
          <IonItem>
          <IonLabel position="stacked">Description</IonLabel>
            <IonTextarea
              maxlength={2000}
              rows={15}
              value={description}
              placeholder="Argument description"
              onIonChange={e => setDescription(e.detail.value!)}>
            </IonTextarea>
          </IonItem>
          <IonItem>
            <IonSegment onIonChange={e => setSide(e.detail.value === "pro" ? true : false)}>
              <IonSegmentButton value="pro">
                <IonLabel>Pro</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="against">
                <IonLabel>Against</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonItem>
        </IonList>
        <IonButton onClick={submit}>Submit Argument</IonButton>
      </IonContent>
    </Layout>
  );
};

export default withRouter(NewArgument);
