import { IonList, IonItem, IonLabel, IonText } from '@ionic/react';
import React from 'react';

const ArgDebateItem = ({ debate, side, debateId }) => {

  return (
    <IonList>
      <IonItem lines="full" routerLink={"/debates/"+debateId}>
        <IonLabel>
          {side ? (
            <div>
              <IonText color="success">
                <p>Argument pro</p>
              </IonText>
              <h2>{debate.title}</h2>
            </div>
          ) : (
            <div>
              <IonText color="danger">
                <p>Argument against</p>
              </IonText>
              <h2>{debate.title}</h2>
            </div>
          )}
        </IonLabel>
      </IonItem>
    </IonList>
  )
};

export default ArgDebateItem
