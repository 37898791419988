import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IonPage, IonContent, IonInput, IonList, IonItem, IonButton, IonLabel, IonHeader, IonToolbar, IonTitle, IonToast } from '@ionic/react';
import { useAuth } from '../api/context'

const SignUp = ({...props}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { signup } = useAuth()

  const handleSignUp = () => {
    setError('')
    signup(name, email, password)
    .then(res => props.history.push("/"))
    .catch(e => setError(e.message))
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Sign up</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonToast isOpen={!!error} message={error} color='warning'/>
        <IonList lines="full">
          <IonItem>
            <IonLabel position="stacked">Name</IonLabel>
            <IonInput
              type="text"
              onIonChange={(e) => {setName(e.detail.value);}}
              name="name"
              value={name}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Email</IonLabel>
            <IonInput
              type="email"
              onIonChange={(e) => {setEmail(e.detail.value);}}
              name="email"
              value={email}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Password</IonLabel>
            <IonInput
              type="password"
              onIonChange={(e) => {setPassword(e.detail.value);}}
              name="email"
              value={password}
            />
          </IonItem>
        </IonList>
        <IonButton onClick={handleSignUp} expand="block" class="ion-margin">Sign Up</IonButton>
        <div className="ion-text-center">
          <p>Already have an account?</p>
          <Link to="/login">Login</Link>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default SignUp;
