import { IonContent, IonList, IonProgressBar, IonItem, IonLabel, IonText } from '@ionic/react';
import React, {useState} from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { db } from "../firebase";
import Layout from './Layout';

const Debates = () => {

  const [debates, setDebates] = useState([]);
  const [loading, setLoading] = useState(true);

  useAsyncEffect(async() => {
    const debates = await db.collection('debates').get()
    .then(snapshot => snapshot.docs.map(doc => ({id:doc.id, ...doc.data()})))
    setDebates(debates)
    setLoading(false)
  }, []);

  return (
    <Layout name='Debates'>
      <IonContent fullscreen>
        {loading ? (
          <IonProgressBar type="indeterminate"/>
        ) : (
          <IonList>
            {debates.map(doc => (
              <IonItem lines="full" key={doc.id} button routerLink={'/debates/'+doc.id} text-wrap={true}>
                <IonLabel>
                  <IonText color="primary">
                    <h5>{doc.topic}</h5>
                  </IonText>
                  <h2>{doc.title}</h2>
                  <p>{doc.argumentCount} arguments</p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}
      </IonContent>
    </Layout>
  );
};

export default Debates;
