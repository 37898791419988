const topics = [
  'Politics',
  'Humanities',
  'Life and self',
  'Science',
  'Technology',
  'Engineering',
  'Sports',
  'Travel',
  'Literature',
  'Languages',
  'Communication',
  'Business',
  'Work',
  'Careers',
  'Philosophy',
  'Art',
  'Design',
  'Style',
  'Medecine',
  'Healthcare',
  'Food',
  'Education',
  'News',
  'Entertainment',
  'Pop culture',
  'Other'
];

export default topics
