const flags = [
  {id:'flag1', label:'Funny'},
  {id:'flag2', label:'Irrelevant'},
  {id:'flag3', label:'Caricatures reality'},
  {id:'flag4', label:'Personal attack'},
  {id:'flag5', label:'Strawman'},
  {id:'flag6', label:'Appeal to popularity'},
  {id:'flag7', label:'Very factual'},
  {id:'flag8', label:'Appeals to emotion'},
  {id:'flag9', label:'False dilemma'},
  {id:'flag10', label:'Slippery slope'},
  {id:'flag11', label:'Hasty generalisation'},
];

export default flags
